<template>
  <div id="download-app" class="mt-5">
    <div class="container px-5 py-5">
      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-md-5">
          <div class="row d-flex justify-content-center mb-5">
            <div class="col-4">
              <router-link to="/" href="#" title="Govava Gift Store">
                <img
                  src="@/assets/site/images/govava-logo-new.png"
                  alt="Govava Gifts"
                  class="img-fluid"
                />
              </router-link>
            </div>
          </div>
          <h1 class="download-title text-center">Find the perfect gift...</h1>
          <h2 class="mb-3 text-center">Download the app today...!</h2>
          <div class="row d-flex justify-content-sm-center">
            <div class="col-md-4 col-6">
              <a
                href="https://apps.apple.com/in/app/govava/id1555186188"
                target="_blank"
              >
                <img src="@/assets/site/images/app_store.png" class="" alt="" />
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-7">
          <img src="@/assets/site/images/mockup-1.webp" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DownloadApp",
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Discover the perfect gift for any occasion! Download the Govava app today to explore a wide range of gifts and enjoy a seamless shopping experience",
      },
      {
        name: "keywords",
        content:
          "Download app, Perfect gift, Govava Gifts, App Store, Mobile app, Gift store, User-friendly design, Gift ideas, App download",
      },
    ],
  },
  components: {},
};
</script>
